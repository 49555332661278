import React, { useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import 'owl.carousel';
import pumpImage from '../assets/images/pump 5.jpg';
import pineappleImage from '../assets/images/pineapple.jpg'; // Ensure this path is correct
import newImage from '../assets/images/newImage.jpg'; // Import your new image

const Carousel = () => {
  useEffect(() => {
    // Ensure jQuery is globally available
    const jQuery = window.$ || $;

    const carouselElement = jQuery('.carousel');

    // Initialize carousel
    carouselElement.owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      items: 1,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
    });

    // Cleanup function to destroy the carousel on unmount
    return () => {
      carouselElement.trigger('destroy.owl.carousel');
    };
  }, []);

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black overlay with 50% opacity
    zIndex: 1, // Ensure the overlay is above the image but below the text
  };

  const captionStyle = {
    zIndex: 2, // Make sure the caption is above the overlay
  };

  return (
    <div className="carousel owl-carousel">
      {/* New image with overlay */}
      <div className="item" style={{ position: 'relative' }}>
        <img className="w-100" src={newImage} alt="New Image" />
        <div style={overlayStyle}></div> {/* Inline overlay */}
        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center" style={captionStyle}>
          <div className="p-3" style={{ maxWidth: '900px' }}>
            <h5 className="text-white text-uppercase mb-3 animated slideInDown">New Innovation & Technology</h5>
            <h1 className="display-1 text-white mb-md-4 animated zoomIn">AgrowTrack : User Friendly Dashboard</h1>
            <a href="/contact" className="btn btn-success py-md-3 px-md-5 me-3 animated slideInLeft" style={{ color: '#ffffff' }} onMouseOver={(e) => e.target.style.color = '#000'} onMouseOut={(e) => e.target.style.color = '#ffffff'}>
              Contact Us
            </a>
          </div>
        </div>
      </div>
      
      <div className="item" style={{ position: 'relative' }}>
        <img className="w-100" src={pumpImage} alt="Agventure" />
        <div style={overlayStyle}></div> {/* Inline overlay */}
        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center" style={captionStyle}>
          <div className="p-3" style={{ maxWidth: '900px' }}>
            <h5 className="text-white text-uppercase mb-3 animated slideInDown">Innovation & Technology</h5>
            <h1 className="display-1 text-white mb-md-4 animated zoomIn">Agventure: Innovative Water Pump</h1>
            <a href="/contact" className="btn btn-success py-md-3 px-md-5 me-3 animated slideInLeft" style={{ color: '#ffffff' }} onMouseOver={(e) => e.target.style.color = '#000'} onMouseOut={(e) => e.target.style.color = '#ffffff'}>
              Contact Us
            </a>
          </div>
        </div>
      </div>

      <div className="item" style={{ position: 'relative' }}>
        <img className="w-100" src={pineappleImage} alt="PinePack Pro" />
        <div style={overlayStyle}></div> {/* Inline overlay */}
        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center" style={captionStyle}>
          <div className="p-3" style={{ maxWidth: '900px' }}>
            <h5 className="text-white text-uppercase mb-3 animated slideInDown">Innovation & Technology</h5>
            <h1 className="display-1 text-white mb-md-4 animated zoomIn">PinePack Pro: Pineapple Sapling Bundling System</h1>
            <a href="/contact" className="btn btn-success py-md-3 px-md-5 me-3 animated slideInLeft" style={{ color: '#ffffff' }} onMouseOver={(e) => e.target.style.color = '#000'} onMouseOut={(e) => e.target.style.color = '#ffffff'}>
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
