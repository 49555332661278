import React from 'react';
import './Login.css';
import Topbar from './Topbar';
import logo from '../assets/images/Logo-png.png'; // Ensure this path is correct

const Login = () => {
  return (
    <>
    <Topbar/>
      <div className="topbar">
        <img src={logo} alt="Company Logo" className="topbar-logo" />
      </div>
      
      <div className="login-container">
        <div className="login-box">
          <div className="login-form">
            <h2>Login</h2>
            <form>
              <div className="login-input">
                <input type="text" placeholder="Username" />
              </div>
              <div className="login-input">
                <input type="password" placeholder="Password" />
              </div>
              <div className="login-remember">
                <input type="checkbox" id="rememberMe" />
                <label htmlFor="rememberMe">Remember me |</label>
                <a href="#" className="forgot-password">| Forgot your password?</a>
              </div>
              <button type="submit" className="login-button">Login</button>
            </form>
          </div>
          <div className="login-welcome">
            <h1>Welcome Back!</h1>
            <p>Please login to continue</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
