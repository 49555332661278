import React, { useEffect } from 'react';
import 'wowjs/css/libs/animate.css';
import { WOW } from 'wowjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { IoSpeedometerOutline, IoBasketOutline, IoAnalyticsOutline, IoLeafOutline, IoWaterOutline, IoCloudOutline, IoHomeOutline, IoFishOutline } from 'react-icons/io5'; // Import specific icons
import './Services.css'; // Import custom CSS

const Services = () => {
  useEffect(() => {
    new WOW().init();
  }, []);

  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '1000px' }}>
          <h5 className="fw-bold text-success text-uppercase">Products and Services</h5>
          <br></br>
          <h1 className="mb-0">Embark on a Journey of Innovation and Technology with our Forward-thinking Solutions</h1>
        </div>

        {/* Our Products Section */}
        <h3 className="text-center text-success fw-bold mb-5">Our Products</h3>
        <div className="row g-5">
          {/* AGROWTRACK card as the first item */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <IoAnalyticsOutline size="3em" color="#ffffff" /> {/* Real-time data icon */}
              </div>
              <h4 className="mb-3">AGROWTRACK</h4>
              <p className="m-0">Revolutionizing Modern Agriculture with Precision and Intelligence. Enhance your farm management with data-driven insights and smart technology.</p>
              <a className="btn btn-lg btn-success rounded-circle" href="https://user.agrowtein.com" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>

          {/* AGVENTURE card */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <IoSpeedometerOutline size="3em" color="#ffffff" /> {/* Use specific icon with white color */}
              </div>
              <h4 className="mb-3">AGVENTURE</h4>
              <p className="m-0">Experience the efficiency and versatility of our vertical axis tiltable water pump, designed to optimize agricultural irrigation with precision and ease.</p>
              <a className="btn btn-lg btn-success rounded-circle" href="/AGVENTURE">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>

          {/* PINEPACK PRO card */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <IoBasketOutline size="3em" color="#ffffff" /> {/* Use specific icon with white color */}
              </div>
              <h4 className="mb-3">PINEPACK PRO</h4>
              <p className="m-0">Revolutionize your pineapple cultivation with our state-of-the-art sapling bundling machine.</p>
              <a className="btn btn-lg btn-success rounded-circle" href="/pinepack">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Our Services Section */}
        <h3 className="text-center fw-bold mt-5 mb-5 text-success">Our Services</h3>
        <div className="row g-5">
          {/* Paddy Field Automation */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="flip-card bg-success"> {/* Apply bg-success to match heading */}
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="service-icon">
                    <IoLeafOutline size="3em" color="#ffffff" /> {/* Paddy Field Automation icon */}
                  </div>
                  <h4 className="mb-3 product-heading">Paddy Field Automation</h4>
                </div>
                <div className="flip-card-back">
                  <p className="text-white m-0">Optimizing paddy field irrigation and fertilization with our cutting-edge automation systems.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Greenhouse Automation */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="flip-card bg-success"> {/* Apply bg-success to match heading */}
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="service-icon">
                    <IoHomeOutline size="3em" color="#ffffff" /> {/* Greenhouse Automation icon */}
                  </div>
                  <h4 className="mb-3 product-heading">Greenhouse Automation</h4>
                </div>
                <div className="flip-card-back">
                  <p className="text-white m-0">Smart solutions for climate control and automated irrigation inside greenhouses.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Hydroponics */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="flip-card bg-success"> {/* Apply bg-success to match heading */}
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="service-icon">
                    <IoWaterOutline size="3em" color="#ffffff" /> {/* Hydroponics icon */}
                  </div>
                  <h4 className="mb-3 product-heading">Hydroponics</h4>
                </div>
                <div className="flip-card-back">
                  <p className="text-white m-0">Automated systems for soil-less farming, enhancing water efficiency and plant growth.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Mushroom Farm Automation */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="flip-card bg-success"> {/* Apply bg-success to match heading */}
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="service-icon">
                    <IoCloudOutline size="3em" color="#ffffff" /> {/* Mushroom Farm Automation icon */}
                  </div>
                  <h4 className="mb-3 product-heading">Mushroom Farm Automation</h4>
                </div>
                <div className="flip-card-back">
                  <p className="text-white m-0">Automating mushroom farm conditions for optimal temperature, humidity, and CO2 levels.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Aquaculture Automation */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="flip-card bg-success"> {/* Apply bg-success to match heading */}
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="service-icon">
                    <IoFishOutline size="3em" color="#ffffff" /> {/* Aquaculture Automation icon */}
                  </div>
                  <h4 className="mb-3 product-heading">Aquaculture Automation</h4>
                </div>
                <div className="flip-card-back">
                  <p className="text-white m-0">Smart sensors and automated feeding systems for efficient fish farming and aquaculture.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Us Section */}
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="position-relative bg-success rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
              <h3 className="text-white mb-3">Call Us For Queries</h3>
              <p className="text-white mb-3">We value your feedback and inquiries. Getting in touch with us is easy!</p>
              <h2 className="text-white mb-0">+91 956 260 3120</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
