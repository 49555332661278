import React from 'react';

// Importing images
import icreateImg from '../assets/images/icreate.png';
import kovilakathImg from '../assets/images/Kovilakath.png';
import kufosImg from '../assets/images/KUFOS.png';
import nitImg from '../assets/images/NIT.png';
import kauImg from '../assets/images/KAU.png';
import ajceImg from '../assets/images/Ajce.png';
import tbiImg from '../assets/images/TBI.png';
import mgImg from '../assets/images/MGU.png';
import biicImg from '../assets/images/biic .jpg';
import startupImg from '../assets/images/StartupImg.png';
import ksumImg from '../assets/images/ksum.png';

const Vendor = () => {
  return (
    <div className="container-fluid py-5">
      <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: '600px' }}>
        <h5 className="fw-bold text-success text-uppercase">Our Supporting Partners</h5>
      </div>
      <div className="container py-5 mb-4">
        <div className="bg-white vendor-grid">
          <div className="row">
            {/* First row with 6 logos */}
            <div className="col-md-2 col-4">
              <img src={icreateImg} alt="iCreate" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={kovilakathImg} alt="Kovilakath" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={kufosImg} alt="KUFOS" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={nitImg} alt="NIT" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={kauImg} alt="KAU" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={ajceImg} alt="AJCE" className="img-fluid" />
            </div>
          </div>
          <div className="row mt-4">
            {/* Second row with 5 logos */}
            <div className="col-md-2 col-4">
              <img src={mgImg} alt="MGU" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={biicImg} alt="BIIC" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={startupImg} alt="Startup" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={ksumImg} alt="KSUM" className="img-fluid" />
            </div>
            <div className="col-md-2 col-4">
              <img src={tbiImg} alt="TBI" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
