import React, { useState } from 'react';
import './UserCreationPage.css';
import logo from '../assets/images/Logo-png.png'; 

const UserCreationPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [district, setDistrict] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [landmark, setLandmark] = useState('');
  const [pincode, setPincode] = useState('');
  const [productType, setProductType] = useState('');
  const [sensorsUsed, setSensorsUsed] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [devicePassword, setDevicePassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({
      username,
      password,
      name,
      phoneNumber,
      email,
      state,
      district,
      addressLine1,
      addressLine2,
      landmark,
      pincode,
      productType,
      sensorsUsed,
      deviceId,
      devicePassword
    });
  };

  return (
    <>
      <div className="topbar">
        <img src={logo} alt="Company Logo" className="topbar-logo" />
      </div>
      <div className="user-creation-container">
        <div className="user-creation-box">
          <h2>Create User</h2>
          <form onSubmit={handleSubmit}>
            <div className="user-creation-card">
              <h3>Account Info</h3>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="user-creation-card">
              <h3>Address Info</h3>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="District"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Address Line 1"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Address Line 2"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Landmark"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Pincode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="user-creation-card">
              <h3>Device Info</h3>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Type of Product"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Sensors Used"
                  value={sensorsUsed}
                  onChange={(e) => setSensorsUsed(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="text"
                  placeholder="Device ID"
                  value={deviceId}
                  onChange={(e) => setDeviceId(e.target.value)}
                  required
                />
              </div>
              <div className="user-creation-input">
                <input
                  type="password"
                  placeholder="Device Password"
                  value={devicePassword}
                  onChange={(e) => setDevicePassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <button type="submit" className="user-creation-button">Create User</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserCreationPage;
